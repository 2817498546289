import styled from '@emotion/styled';
import { Box, Theme } from '@mui/material';
import jonniLogoWhite from 'assets/jonniLogoWhite.svg';
import colors from 'src/styles/colors';

export const StepsMobileContainer = styled(Box)<{ theme?: Theme }>(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '80px',
  zIndex: 4,
  top: '80px',
  display: 'flex',
  flexDirection: 'row',
  padding: '0 30px',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme?.breakpoints.up('sm')]: {
    padding: '0 70px',
  },
}));

export const StyledStep = styled(Box)<{ nextStep?: boolean }>(({ nextStep }) => ({
  display: nextStep ? 'contents' : 'none',
  fontSize: '16px',
  color: '#fff',
  zIndex: 1,
  alignSelf: 'center',
}));

export const StyledStepsText = styled(Box)<{ center?: number }>(({ center }) => ({
  minWidth: '140px',
  margin: center === 0 ? 'auto' : '0px',
  color: 'white',
}));

export const StyledStepTitle = styled(Box)<{ center?: number }>(({ center }) => ({
  color: `${colors.secondaryBackground}`,
  fontSize: '18px',
  fontWeight: 'bold',
  textAlign: center === 0 ? 'center' : 'right',
}));

export const SignupTopbarContainer = styled(Box)({
  background: colors.primary,
  position: 'fixed',
  width: '100%',
  height: '175px',
  zIndex: 4,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '26px',
});

export const Logo = styled(jonniLogoWhite)({
  height: '32px',
  width: '160px',
});

export const LogoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  margin: '0 auto',
  paddingRight: '24px',
});

export const Padding = styled(Box)({
  paddingBottom: '100px',
});
