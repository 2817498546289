import { Theme, useMediaQuery } from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';
import { socialLinks } from 'src/config';
import Analytics from 'src/helpers/analytics';
import { Logo, LogoContainer, Padding, SignupTopbarContainer } from 'src/styles/signup/signupMobileStyles';
import { WhatsappWhiteIcon } from 'src/styles/topbar';

const WhatsappButtonStyle = { marginTop: '3px' };

const SignupMobileTopbar = () => {
  const isMobileWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const onAppClick = (name: string) => {
    Analytics.logAppButtonClick(name);
  };

  return isMobileWidth ? (
    <>
      <SignupTopbarContainer>
        <LogoContainer>
          <a href={'https://www.' + process.env.GATSBY_JONNI_DOMAIN}>
            <Logo />
          </a>
        </LogoContainer>
        <a
          style={WhatsappButtonStyle}
          onClick={() => onAppClick('whatsapp')}
          href={socialLinks.whatsapp}
          target="_blank"
          rel="noreferrer">
          <WhatsappWhiteIcon />
        </a>
      </SignupTopbarContainer>
      <Padding />
    </>
  ) : null;
};

export default SignupMobileTopbar;
