import { ThemeOptions, createTheme } from '@mui/material/styles';
import { colors } from 'src/theme/colors';
import { fontFamily } from 'src/theme/fonts';
import { jonniMuiThemeOverrides } from 'src/theme/styles';

const themeOptionsLight: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: colors.light.primary,
    },
    secondary: {
      main: colors.light.secondary,
    },
    text: {
      primary: colors.light.text,
      secondary: colors.light.darkBlue,
    },
    error: {
      main: colors.light.error,
    },
    success: {
      main: colors.light.success,
    },
    warning: {
      main: '#000',
      light: '#000',
      dark: '#000',
    },
    action: {
      disabledBackground: '#F6F6F6',
      disabled: '#c3c3c3',
    },
  },
  typography: {
    fontFamily,
  },
  components: jonniMuiThemeOverrides,
  direction: 'rtl' as const,
};

const theme = createTheme(themeOptionsLight);

// Add custom type extensions if needed
declare module '@mui/material/styles' {
  interface Theme {
    // Add any custom theme properties here if needed
  }
  interface ThemeOptions {
    // Add any custom theme options here if needed
  }
}

export default theme;
