/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { ThemeProvider } from '@mui/material/styles';
import { Location } from '@reach/router';
import React from 'react';
import { RecoilRoot } from 'recoil';
import MainLayout from 'src/layouts/MainLayout';
import { initializeServices } from 'src/services/initServices';
import theme from 'src/theme/theme';

// Initialize services in a safe way
if (typeof window !== 'undefined') {
  try {
    initializeServices();
  } catch (error) {
    console.warn('Error initializing services:', error);
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <Location>
      {({ location }) => {
        return (
          <RecoilRoot>
            <ThemeProvider theme={theme}>
              <MainLayout element={element} pathName={location.pathname} />
            </ThemeProvider>
          </RecoilRoot>
        );
      }}
    </Location>
  );
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.pathname !== prevLocation?.pathname) {
    window.scrollTo(0, 0);
  }
};
