//
// Do not change this file, it is managed by the original file in the backend repo `jonni-backend/shared`
//
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { isGraphQlError, isGraphQlNetworkError } from '@jonni/shared/graphql/amplifyFetcher.utils';
import { logger } from '@jonni/shared/utils/logger';
import { generateClient, GraphQLQuery, GraphQLResult, GraphQLSubscription } from 'aws-amplify/api';

type GraphQlSimpleOptions = Omit<GraphQLOptions, 'query' | 'variables'> & {
  query: string;
  variables?: Record<string, unknown> | undefined | null;
};

// Generate an Amplify API client
const client = generateClient();

/**
 * Custom API object with retry logic for GraphQL operations
 */
export const API = {
  /**
   * Executes a GraphQL operation with retry logic
   * @template TData The expected data type of the GraphQL response
   * @template TVariables The variables type for the GraphQL query
   * @template TQuery The typed GraphQL query string
   * @param options The GraphQL options
   * @param attempt The current attempt number (default: 1)
   * @returns A promise with the GraphQL result
   */
  // TODO: remove 'T extends GraphQLQuery<infer R> | GraphQLSubscription<infer R>' once code is refactored
  graphql: async function <T, TData = T extends GraphQLQuery<infer R> | GraphQLSubscription<infer R> ? R : T>(
    options: GraphQlSimpleOptions,
    attempt = 1,
  ): Promise<GraphQLResult<TData>> {
    try {
      const response = await client.graphql(options);
      if ('data' in response) {
        return response as GraphQLResult<TData>;
      } else {
        throw new Error('Unexpected response format. Property "data" not found in response');
      }
    } catch (error) {
      if (isGraphQlError(error) && isGraphQlNetworkError(error) && attempt <= 3) {
        const backOffDelay = Math.pow(2, attempt) * 1000;
        logger.error(
          `GraphQL operation failed due to network error, retrying after ${Math.floor(backOffDelay / 1000)} seconds...`,
        );
        await new Promise((resolve) => setTimeout(resolve, backOffDelay));
        return this.graphql(options, attempt + 1);
      }
      const { query, variables } = options;
      const queryTypeAndName = query.trimStart().match(/^\s*(\w+)\s+(\w+)/);
      const [queryType, queryName] = [queryTypeAndName?.[1] || '', queryTypeAndName?.[2] || ''];
      logger.error(`GraphQL request error [${queryType}] "${queryName}"`, { error, attempt, query, variables });

      // Do not change the thrown error object, it will be used by react-query to handle the error
      throw error;
    }
  },
};

/**
 * Amplify fetcher function for use with react-query
 * @template TData The expected data type of the GraphQL response
 * @template TVariables The variables type for the GraphQL query
 * @param query The GraphQL query string
 * @param variables The variables for the GraphQL query
 * @returns A function that executes the GraphQL query and returns a promise with the data
 */
export function amplifyFetcher<TData, TVariables extends Record<string, unknown> | undefined>(
  query: GraphQlSimpleOptions['query'],
  variables?: TVariables,
): () => Promise<TData> {
  return async (): Promise<TData> => {
    const response = await API.graphql<TData>({ query, variables });
    return response.data as TData;
  };
  // Do not change the thrown error object, it will be used by react-query to handle the error
}
