/**
 * Checks if the environment is local development
 * @returns True if local development environment
 */
export function isEnvLocalDev(): boolean {
  return process.env.GATSBY_APP_ENV === 'devlocal';
}

/**
 * Checks if the environment is development
 * @returns True if development environment
 */
export function isEnvDev(): boolean {
  return ['devlocal', 'dev'].includes(process.env.GATSBY_APP_ENV as string);
}
