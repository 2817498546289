import React from 'react';
import { steps } from 'src/data';
import { MapScreenToStep, Steps } from 'src/recoil/steps/atoms';
import {
  StyledDoneGreen,
  StyledNumberStep,
  StyledStep,
  StyledStepsText,
  StyledStepTitle,
  WrapperSteps,
} from 'src/styles/signup/sideSteps';
import { Done } from 'src/styles/signup/signupSteps';

interface Props {
  activeStep: Steps;
}

const SideSteps: React.FC<Props> = ({ activeStep }) => {
  const step = MapScreenToStep[activeStep];

  return (
    <WrapperSteps>
      {steps.map((item, i) => {
        if (!i) return null;
        if (step === 7 && i === 6)
          return (
            <StyledStep nextStep={i < step} key={item.title}>
              <StyledNumberStep>
                <StyledDoneGreen />
              </StyledNumberStep>

              <StyledStepsText>
                <StyledStepTitle>{item.title}</StyledStepTitle>
              </StyledStepsText>
            </StyledStep>
          );
        return (
          <StyledStep nextStep={i < step} key={item.title}>
            <StyledNumberStep>{i + 1 < step ? <Done /> : item.number}</StyledNumberStep>
            <StyledStepsText>
              <StyledStepTitle>{item.title}</StyledStepTitle>
            </StyledStepsText>
          </StyledStep>
        );
      })}
    </WrapperSteps>
  );
};

export default SideSteps;
