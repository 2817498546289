import React, { useEffect, useState } from 'react';
import SignUpLayout from 'src/layouts/SignupLayout';
import { NavigateToActiveScreen } from 'src/layouts/components/NavigateToActiveScreen';

interface MainLayoutProps {
  element: any;
  pathName: any;
}

const MainLayout: React.FC<MainLayoutProps> = ({ element, pathName }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Remove artificial delay and set loading false immediately after mount
    setLoading(false);
  }, []);

  // Don't hide content during loading, just render it
  if (pathName.includes('signup')) {
    return (
      <>
        <NavigateToActiveScreen />
        <SignUpLayout path={pathName}>{element}</SignUpLayout>
      </>
    );
  }

  return <>{element}</>;
};

export default MainLayout;
