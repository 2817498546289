import { initializeApp } from 'firebase/app';

/**
 * Initializes all client-side services.
 * Only runs in browser environment and when required configuration is present.
 * Services:
 * - Firebase
 * - Bugsnag
 * - LogRocket
 */
export const initializeServices = () => {
  // Only initialize services in browser environment
  if (typeof window === 'undefined') {
    return;
  }

  // Initialize Firebase if config is available
  try {
    if (process.env.GATSBY_FIREBASE_CONFIG_KEY) {
      try {
        const firebaseConfig = JSON.parse(process.env.GATSBY_FIREBASE_CONFIG_KEY);
        initializeApp(firebaseConfig);
      } catch (error) {
        console.warn('Error parsing Firebase config:', error);
      }
    }

    // Initialize monitoring services one by one to isolate errors
    const initializeMonitoringServices = async () => {
      try {
        await import('./BugsnagService');
      } catch (error) {
        console.warn('Error initializing Bugsnag:', error);
      }

      try {
        await import('./LogrocketService');
      } catch (error) {
        console.warn('Error initializing LogRocket:', error);
      }
    };

    initializeMonitoringServices().catch((error) => {
      console.warn('Error initializing monitoring services:', error);
    });
  } catch (error) {
    console.warn('Error in service initialization:', error);
  }
};
