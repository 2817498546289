//
// Do not change this file, it is managed by the original file in the backend repo `jonni-backend/shared`
//
import { logger } from '@jonni/shared/utils/logger';
import { GraphQLError } from 'graphql';

type GraphQlRequestError = { errors: GraphQLError[] };

type GraphQlErrorMessageObject<TData extends Record<string, unknown> = Record<string, unknown>> = {
  message: string;
  data?: { code?: string } & TData;
  code?: string; // summarizes code from data.code or data.errCode
} & Record<string, unknown>; // TODO: remove '& Record<string, unknown>' once we have a proper error convention

/**
 * Parse the GraphQL error and return the error code and if it's a network error
 * @param error The error object
 * @returns An object containing the error, error code, and a boolean indicating if it's a network error
 */
export function parseGraphQlError<TData extends Record<string, unknown> = Record<string, unknown>>(
  error: unknown,
): { error: GraphQlRequestError; isNetworkError?: boolean } & GraphQlErrorMessageObject<TData> {
  if (!isGraphQlError(error)) {
    logger.error('Error is not a GraphQL error', { error });
    const message = error instanceof Error ? error.message : '';
    return { error: error as GraphQlRequestError, message };
  }
  const isNetworkError = isGraphQlNetworkError(error);
  const {
    message,
    data,
    ...parsedRest // TODO: remove '& Record<string, unknown>' once we have a proper error convention
  } = parseGraphQlErrorMessage<TData>(error.errors[0].message);
  return {
    error,
    isNetworkError,
    message,
    data,
    code: data?.code,
    ...parsedRest, // TODO: remove '& Record<string, unknown>' once we have a proper error convention
  };
}

// Utils

/**
 * Check if the error is a GraphQL error
 * @param err The error object
 * @returns True if the error is a GraphQL error, false otherwise
 */
export function isGraphQlError(err: unknown): err is GraphQlRequestError {
  const error = err as GraphQlRequestError;
  return Array.isArray(error?.errors);
}

/**
 * Check if the GraphQL request is a network error
 * @param error The error object
 * @returns True if the error is a network error, false otherwise
 */
export function isGraphQlNetworkError(error: GraphQlRequestError): boolean {
  const firstErrorMessage = error.errors[0].message;
  return firstErrorMessage.toLocaleLowerCase().includes('network error');
}

/**
 * Get the error code from the GraphQL request error
 * @param errorMessage The error message
 * @returns The error code, or undefined if not found
 */
function parseGraphQlErrorMessage<TData extends Record<string, unknown> = Record<string, unknown>>(
  errorMessage: string,
): GraphQlErrorMessageObject<TData> {
  let parsedMessage: GraphQlErrorMessageObject<TData> = { message: errorMessage };

  try {
    parsedMessage = { ...parsedMessage, ...(JSON.parse(errorMessage) as GraphQlErrorMessageObject<TData>) };
  } catch (error) {
    logger.error('Error parsing GraphQL request errorMessage:', { error });
  }

  return parsedMessage;
}
