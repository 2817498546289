import React from 'react';
import { socialLinks } from 'src/config';
import Analytics from 'src/helpers/analytics';
import { BlueWhatsappIcon, HeaderContainer, HeaderLogo, JonniLogo } from 'src/styles/signup/signupSteps';

const SignupLayoutHeader: React.FC = () => {
  const onAppClick = (name: string) => {
    Analytics.logAppButtonClick(name);
  };

  return (
    <HeaderContainer>
      <HeaderLogo>
        <a href={'https://www.' + process.env.GATSBY_JONNI_DOMAIN}>
          <JonniLogo />
        </a>
      </HeaderLogo>
      <a onClick={() => onAppClick('whatsapp')} href={socialLinks.whatsapp} target="_blank" rel="noreferrer">
        <BlueWhatsappIcon />
      </a>
    </HeaderContainer>
  );
};

export default SignupLayoutHeader;
