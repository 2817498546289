import { Components } from '@mui/material/styles';
import { colors } from 'src/theme/colors';

export const jonniMuiThemeOverrides: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: 700,
        padding: '12px 24px',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
          '& fieldset': {
            borderColor: colors.light.borderGray,
          },
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: colors.light.darkBlue,
      },
    },
  },
};
