import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Link } from 'gatsby';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
  renderRichText,
} from 'gatsby-source-contentful/rich-text';
import React from 'react';

export const breakpoints = { xxl: 1920, xl: 1300, l: 992, m: 768, s: 360 };

export const getMQ = (breakpoint: number | string, isMax?: boolean) => {
  return `@media (${isMax ? 'max' : 'min'}-width: ${breakpoint}px)`;
};

const appearKeyframe = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  
  20% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`;

export const appearanceKeyframe = css`
  animation: ${appearKeyframe} 0.7s ease;
`;

const isIos = function (userAgent: string) {
  const iosQuirkPresent = function () {
    let audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1;
  };

  const isIOS = /iPad|iPhone|iPod/i.test(userAgent);
  const isAppleDevice = userAgent.includes('Macintosh');
  const isTouchScreen = window.navigator.maxTouchPoints >= 1;

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
};

export const getOS = () => {
  const userAgent = window && window.navigator.userAgent,
    platform = window && window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

  let os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = null;
  } else if (isIos(userAgent)) {
    os = 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = null;
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = null;
  }

  return os;
};

export const validateEmail = (email: string) => {
  const tester =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) return false;
  if (email.length > 256) return false;
  if (!tester.test(email)) return false;

  const emailParts = email.split('@');
  const account = emailParts[0];
  const address = emailParts[1];
  if (account.length > 64) return false;

  const domainParts = address.split('.');
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  return true;
};

export const HomePageContentBlockButton = styled(Link)`
  display: flex;
  margin-top: 32px;
  border-radius: 48px;
  border: 2px solid #4358f2;
  background: #4357f2;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  padding: 6px 38px;
  transition: all 0.5s;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  max-width: 234px;

  &:hover {
    background: #fff;
    border: 2px solid #d9d9d9;
    color: #4358f2;
  }
`;

export const RichTextButton = styled(Link)`
  color: #4357f2;
  cursor: pointer;
  fontweight: bold;

  &:hover {
    opacity: 0.8;
  }
`;

type BlogBodyProps = {
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  styledLink?: boolean;
};

export const RichText = ({ content, styledLink }: BlogBodyProps) => {
  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) =>
        styledLink ? (
          <HomePageContentBlockButton to={data.uri} target="_blank">
            {children}
            <ArrowBackIosIcon sx={{ fontSize: 22 }} />
          </HomePageContentBlockButton>
        ) : (
          <RichTextButton to={data.uri} target="_blank">
            {children}
          </RichTextButton>
        ),
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <>
            {children}
            <br />
          </>
        );
      },
    },
  };
  return <>{renderRichText(content, options)}</>;
};

const SvgBlock = styled.div`
  max-width: 100%;
  img {
    max-width: 100%;
  }
`;

export function SvgImage({ svg, file }: { svg: any; file?: any }) {
  if (file.contentType === 'image/svg+xml') {
    if (svg && svg.content) {
      return (
        <SvgBlock>
          <img src={`data:image/svg+xml;utf8,${encodeURIComponent(svg.content)}`} alt="Icon" />
        </SvgBlock>
      );
    }
    return null;
  }
  return null;
}

export function isHebrewString(str: string) {
  if (/[.,'\-ְֱֲֳִֵֶַָֻּ]/.test(str)) {
    return false;
  }

  return /^[\u0590-\u05FF ,.'-]+$/i.test(str);
}

export function isEnglishString(str: string) {
  return /^[A-Za-z\s]+$/i.test(str);
}

export function isHebrewAddress(str: string) {
  return /^[\u0590-\u05FF ,.'-\/\d"]+$/i.test(str);
}

export function createPassword(length: number) {
  const strongPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$~&*])(?=.{12,})');
  let newPassword = '';
  do {
    newPassword = Array(length + 5)
      .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@#$&*')
      .map((x) => {
        const randomInt = Math.floor(Math.random() * (4294967295 - 600000000 + 1) + 600000000);
        const index = Math.floor((randomInt / (0xffffffff + 1)) * x.length);
        return x[index];
      })
      .join('');
  } while (strongPassword.test(newPassword) === false);

  return newPassword;
}

export function getFormattedNumber(phoneNumber: string) {
  return '+972' + phoneNumber.slice(1);
}

export const getDiscount = (total: number, discountPercentage: number | undefined) => {
  if (!discountPercentage || discountPercentage === 0) return total;

  const discountAmount = total * discountPercentage;
  return total - discountAmount;
};

const client = generateClient();
export const API = {
  graphql: async function (options: { query: string; variables?: object }, attempt = 1) {
    try {
      const authSession = await fetchAuthSession();
      if (!authSession.userSub) throw Error('not authenticated');
    } catch (err) {
      localStorage.clear();
      window.history.pushState({}, '', '/signup/WELCOME');
      window.location.reload();
      return;
    }

    try {
      return await client.graphql(options);
    } catch (err: any) {
      const error = err?.errors[0];
      if ((error?.message || '').includes('Network Error') && attempt < 3) {
        this.graphql(options, attempt + 1);
      } else {
        throw err;
      }
    }
  },
};

export const VAT_PERCENTAGE_INCLUDED_MULTIPLIER = 1.18;
export const VAT_PERCENTAGE = 0.18;
