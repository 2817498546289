//
// Do not change this file, it is managed by the original file in the backend repo `jonni-backend/shared`
//
import { bugsnag } from '@jonni/services/BugsnagService';
import { isEnvLocalDev } from '@jonni/common/utils';

let inspect = (data: unknown, _options: object) => data;
try {
  void import('util').then((util) => {
    if (util.inspect) {
      inspect = util.inspect;
    }
  });
  // eslint-disable-next-line no-empty
} catch (_e) {}

/**
 * Types for the logger service
 */
type MetaData = Record<string, unknown>;
type Severity = 'info' | 'warning' | 'error';

/**
 * Inspects metadata and returns a string representation
 * @param metadata - The metadata to inspect
 * @returns A string representation of the metadata
 */
const inspectMetadata = (metadata: MetaData): ReturnType<typeof inspect> =>
  inspect(metadata, { colors: true, depth: null });

/**
 * Creates and sends a report to Bugsnag with the specified severity and metadata
 * @param msg - The message or Error object to be reported
 * @param severity - The severity level of the error ('info' | 'warning' | 'error')
 * @param metadata - Additional context data for the error
 */
const createBugsnagReport = (msg: string, severity: Severity, metadata: MetaData = {}): void => {
  bugsnag.notify(new Error(msg), (report) => {
    report.severity = severity;
    report.addMetadata('extraData', metadata);
  });
};

/**
 * Logger service that provides different logging levels and handles both development
 * and production environments appropriately
 */
const logger = {
  /**
   * Logs informational messages
   * @param msg - The message or Error object to log
   * @param metadata - Additional context data for the log
   */
  info(msg: string, metadata: MetaData = {}): void {
    if (isEnvLocalDev()) {
      console.log(msg, inspectMetadata(metadata));
    } else {
      createBugsnagReport(msg, 'info', metadata);
    }
  },

  /**
   * Logs warning messages
   * @param msg - The message or Error object to log as a warning
   * @param metadata - Additional context data for the warning
   */
  warn(msg: string, metadata: MetaData = {}): void {
    if (isEnvLocalDev()) {
      console.warn(msg, inspectMetadata(metadata));
    } else {
      createBugsnagReport(msg, 'warning', metadata);
    }
  },

  /**
   * Logs error messages
   * @param msg - The message or Error object to log as an error
   * @param metadata - Additional context data for the error
   */
  error(msg: string, metadata: MetaData = {}): void {
    if (isEnvLocalDev()) {
      console.error(msg, inspectMetadata(metadata));
    } else {
      createBugsnagReport(msg, 'error', metadata);
    }
  },

  /**
   * Logs debug messages
   * @param msg - The message or data to log for debugging
   * @param metadata - Additional context data for the debug log
   */
  debug(msg: string, metadata: MetaData = {}): void {
    if (isEnvLocalDev()) {
      console.log('🐞');
      console.log(msg, inspectMetadata(metadata));
    }
  },
};

export { logger };
